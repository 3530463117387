import React from "react";
import { Stack, Typography, Box, Grid } from "@mui/material";
import { Trans } from "@lingui/macro";
import styled from "@emotion/styled";
import { faChevronUp, faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Container from "../../components/Container";
import NewLayout from "../../components/NewLayout";
import BoxDetails from "../../components/BoxDetails";
import TwoColumnsSection from "../../components/TwoColumnsSection";
import platform_image from "../../images/about/services/platform.png";
import blockchain_image from "../../images/about/services/blockchain.png";
import data_image from "../../images/about/services/data.png";
import esg_image from "../../images/about/services/esg.png";
import investment_image from "../../images/about/services/investment.png";
import tech_image from "../../images/about/services/tech.png";
import home_card_3_image from "../../images/home-card-3.png";
import home_card_4_image from "../../images/home-card-4.png";
import Flag from "../../components/Flag";
import ImageCard from "../../components/ImageCard";
import whatDoImage from "../../images/about/what-do.png";
import whoAreImage from "../../images/about/who-are.png";
import InterestedItems from "../../components/InterestedItems";

const contentImages = () => [
  {
    title: <Trans>Investment Solutions</Trans>,
    description: (
      <Trans>
        A range of investment and outsourcing solutions for banks, wealth
        managers and institutional investors.
      </Trans>
    ),
    image: home_card_3_image,
    path: "/fund-houses/investment",
  },
  {
    title: <Trans>Allfunds Blockchain</Trans>,
    description: (
      <Trans>
        A holistic and trailblazing blockchain platform to technologically
        transform the investment fund landscape.
      </Trans>
    ),
    image: home_card_4_image,
    path: "/distributors/blockchain",
  },
];

const dataStructure = () => [
  {
    title: <Trans>Subsidiaries</Trans>,
    items: [
      {
        title: <Trans>ALLFUNDS Digital, S.L.U.</Trans>,
        country: <Trans>Spain</Trans>,
        code: "ES",
        items: [
          {
            title: <Trans>Allfunds Data Analytics Limited.</Trans>,
            country: <Trans>United Kingdom</Trans>,
            code: "GB",
          },
          {
            title: (
              <>
                <Trans>MainStreet Capital Partners Limited.</Trans>*
              </>
            ),
            country: <Trans>United Kingdom</Trans>,
            code: "GB",
            items: [
              {
                title: <Trans>MainStreet Analytics Limited.</Trans>,
                country: <Trans>United Kingdom</Trans>,
                code: "GB",
              },
            ],
          },
          {
            title: <Trans>Allfunds Tech Solutions, S.A.U.</Trans>,
            country: <Trans>Spain</Trans>,
            code: "ES",
            size: 240,
            items: [
              {
                title: <Trans>Allfunds Tech Solutions Sweden AB.</Trans>,
                country: <Trans>Sweden</Trans>,
                code: "SE",
              },
              {
                title: <Trans>Allfunds Tech Solutions UK Limited.</Trans>,
                country: <Trans>United Kingdom</Trans>,
                code: "GB",
              },
              {
                title: <Trans>Allfunds Tech Solutions Switzerland AG.</Trans>,
                country: <Trans>Switzerland</Trans>,
                code: "CH",
              },
              {
                title: <Trans>Allfunds Tech Solutions Germany GmbH.</Trans>,
                country: <Trans>Germany</Trans>,
                code: "DE",
              },
              {
                title: <Trans>Allfunds Tech Solutions France</Trans>,
                country: <Trans>France</Trans>,
                code: "FR",
              },
            ],
          },
        ],
      },
      {
        title: <Trans>ALLFUNDS Nominee Ltd.</Trans>,
        country: <Trans>United Kingdom</Trans>,
        code: "GB",
      },
      {
        title: <Trans>ALLFUNDS Hong Kong Limited</Trans>,
        country: <Trans>Hong Kong SAR China</Trans>,
        code: "HK",
      },
      {
        title: <Trans>Allfunds Blockchain S.L.U.</Trans>,
        country: <Trans>Spain</Trans>,
        code: "ES",
      },
      {
        title: <Trans>Allfunds Investment Solutions, S.A.</Trans>,
        country: <Trans>Luxembourg</Trans>,
        code: "LU",
      },
      {
        title: (
          <Trans>
            Allfunds Information & Technology Services (Shanghai) Co., Ltd.
          </Trans>
        ),
        country: <Trans>People's Republic of China</Trans>,
        code: "CN",
      },
    ],
  },
  {
    title: <Trans>Rep Offices</Trans>,
    items: [
      {
        title: <Trans>ALLFUNDS, S.A.U. Representation Office in DIFC</Trans>,
        country: <Trans>United Arab Emirates</Trans>,
        code: "AE",
      },
      {
        title: (
          <Trans>ALLFUNDS BANK, S.A.U. Representation Office in Bogota</Trans>
        ),
        country: <Trans>Colombia</Trans>,
        code: "CO",
      },
      {
        title: (
          <Trans>
            ALLFUNDS BANK, S.A.U. Representation Office in Santiago de Chile
          </Trans>
        ),
        country: <Trans>Chile</Trans>,
        code: "CL",
      },
      {
        title: (
          <Trans>ALLFUNDS BANK, S.A.U. Representation Office in Miami</Trans>
        ),
        country: <Trans>EEUU</Trans>,
        code: "US",
      },
      {
        title: <Trans>ALLFUNDS BANK Brazil REPRESENTACOES L TDA</Trans>,
        country: <Trans>Brazil</Trans>,
        code: "BR",
      },
    ],
  },
  {
    title: <Trans>Branches</Trans>,
    items: [
      {
        title: <Trans>ALLFUNDS BANK, S.A.U. London Branch</Trans>,
        country: <Trans>United Kingdom</Trans>,
        code: "GB",
      },
      {
        title: <Trans>ALLFUNDS BANK, S.A.U. Milan Branch</Trans>,
        country: <Trans>Milan</Trans>,
        code: "IT",
      },
      {
        title: <Trans>ALLFUNDS BANK, S.A.U. Luxembourg Branch</Trans>,
        country: <Trans>Luxembourg</Trans>,
        code: "LU",
      },
      {
        title: <Trans>ALLFUNDS BANK, S.A.U. Madrid, Zurich Branch</Trans>,
        country: <Trans>Switzerland</Trans>,
        code: "CH",
      },
      {
        title: <Trans>ALLFUNDS Singapore Branch</Trans>,
        country: <Trans>Singapore</Trans>,
        code: "SG",
      },
      {
        title: (
          <Trans>ALLFUNDS BANK, S.A. Stockholm Branch Sweden, Filial</Trans>
        ),
        country: <Trans>Sweden</Trans>,
        code: "SE",
      },
      {
        title: <Trans>ALLFUNDS BANK, S.A.U. Paris Branch</Trans>,
        country: <Trans>Paris Branch</Trans>,
        code: "FR",
      },
      {
        title: <Trans>ALLFUNDS BANK, S.A.U. Warsaw Branch</Trans>,
        country: <Trans>Poland</Trans>,
        code: "PL",
      },
    ],
  },
];

const contentStructure = () => [
  {
    title: <Trans>Allfunds Group plc</Trans>,
    country: <Trans>United Kingdom</Trans>,
    code: "GB",
    color: "#D50032",
  },
  {
    title: <Trans>Liberty Partners, S.L.U.</Trans>,
    country: <Trans>Spain</Trans>,
    code: "ES",
    color: "white",
  },
  {
    title: <Trans>Allfunds Bank, S.A.U.</Trans>,
    description: (
      <Trans>Spanish Credit Entity Supervised by the Bank of Spain</Trans>
    ),
    country: <Trans>Spain</Trans>,
    code: "ES",
    color: "rgba(245, 245, 245, 0.5)",
    content: dataStructure(),
  },
];

const contentOferring = () => [
  {
    title: <Trans>Fund Distribution & Platform Services</Trans>,
    description: (
      <Stack spacing={1}>
        <Typography>
          <Trans>
            Allfunds allows clients to trade with a myriad of investment funds
            through a central integrated platform that offers a secure
            transaction process, minimising the risk of operational errors.
          </Trans>
        </Typography>
        <Typography>
          <Trans>
            Allfunds also provides its clients with advanced tools to support
            their fund selection decisions and distribution strategies. From
            fund screening, integrated dashboards, monitoring and compliance
            support to regulatory documentation and reporting.
          </Trans>
        </Typography>
      </Stack>
    ),
    image: platform_image,
  },
  {
    title: <Trans>Data and Analytics Solutions</Trans>,
    description: (
      <Stack spacing={1}>
        <Typography>
          <Trans>
            Allfunds provides its clients with advanced reporting with
            comprehensive data on their assets and flows.
          </Trans>
        </Typography>
        <Typography>
          <Trans>
            Allfunds’ market intelligence services provide access and insights
            to its extensive, and for the most part real-time, transactional
            market data pool.
          </Trans>
        </Typography>
      </Stack>
    ),
    image: data_image,
  },
  {
    title: <Trans>Tech Solutions</Trans>,
    description: (
      <Typography>
        <Trans>
          The Allfunds Tech Solutions team develops end-to-end software
          solutions and open B2C channels for our partners by leveraging our
          data management capabilities, superior tech expertise and a
          comprehensive knowledge of the fund distribution environment.
        </Trans>
      </Typography>
    ),
    image: tech_image,
  },
  {
    title: <Trans>ESG Solutions</Trans>,
    description: (
      <Typography>
        <Trans>
          Allfunds’ ESG tools address and support the growing demand for
          sustainable investing strategies.
        </Trans>
      </Typography>
    ),
    image: esg_image,
  },
  {
    title: <Trans>Blockchain Solutions</Trans>,
    description: (
      <Stack spacing={1}>
        <Typography>
          <Trans>
            Allfunds Blockchain is a dedicated software company focused on
            developing solutions for the entire fund industry.
          </Trans>
        </Typography>
        <Typography>
          <Trans>
            The team seeks to increase efficiency in fund distribution activity
            in terms of speed, transparency and cost through its trailblazing
            blockchain technology.
          </Trans>
        </Typography>
      </Stack>
    ),
    image: blockchain_image,
  },
  {
    title: <Trans>ManCo & Investment Solutions</Trans>,
    description: (
      <Stack spacing={1}>
        <Typography>
          <Trans>
            Allfunds provides investment outsourcing and ManCo services for
            banks, wealth managers and institutional investors.
          </Trans>
        </Typography>
        <Typography>
          <Trans>
            Allfunds’ robust service offering, combined with its extensive fund
            distribution network, helps partners streamline their operations and
            maximise their sales efforts.
          </Trans>
        </Typography>
      </Stack>
    ),
    image: investment_image,
  },
];

function AboutPage() {
  const [showStructureContent, setShowStructureContent] =
    React.useState(undefined);

  const renderCountryFlag = (country, code, color) => (
    <Box display="flex">
      <Flag country={code} />
      <StyledTypography
        parentColor={color}
        ml={1}
        variant="small"
        fontStyle="italic"
      >
        {country}
      </StyledTypography>
    </Box>
  );

  const renderChilds = (currentItems, level = 0) => (
    <>
      {currentItems.map(({ title, country, code, items, size }, index) => (
        <Box>
          <StyledSubMenu level={level} isFirst={index === 0} size={size}>
            <Box mt={1} p={1}>
              <Box mb={1}>
                <Typography variant="h5">{title}</Typography>
              </Box>
              {renderCountryFlag(country, code, "white")}
            </Box>
          </StyledSubMenu>
          {items?.length > 0 && renderChilds(items, level + 1)}
        </Box>
      ))}
    </>
  );

  return (
    <NewLayout byDefault apps="about">
      <Box sx={{ pb: { xs: 3, md: 6 } }}>
        <Container>
          <Typography mt={3} variant="h2">
            <Trans>About Us</Trans>
          </Typography>
        </Container>
      </Box>
      <Container pb={10}>
        <TwoColumnsSection
          sx={{ mb: { xs: 5, sm: 10 } }}
          color="#D50032"
          image={whoAreImage}
          title={<Trans>Who We Are</Trans>}
          description={
            <Stack spacing={1}>
              <Typography>
                <Trans>
                  Allfunds (AMS: ALLFG) is one of the leading global WealthTech
                  companies with a service offering tailored for Fund Houses and
                  Distributors that ranges from dealing and execution services,
                  to data analytics, reporting and portfolio tools, ESG advisory
                  and custom software solutions.
                </Trans>
              </Typography>
              <Typography>
                <Trans>
                  For more than twenty years, Allfunds has created an efficient,
                  secure ecosystem that hosts the largest fund distribution
                  network, offering access to the world’s most comprehensive
                  universe of mutual funds, ETFs and alternative assets.
                </Trans>
              </Typography>
            </Stack>
          }
        />
        <TwoColumnsSection
          sx={{ mb: { xs: 5, sm: 10 } }}
          color="#D50032"
          image={whatDoImage}
          title={<Trans>What We Do</Trans>}
          description={
            <Stack spacing={1}>
              <Typography>
                <Trans>
                  We connect financial institutions and deliver digital
                  solutions to cover the entire fund distribution value chain,
                  for both Distributors and Fund Houses.
                </Trans>
              </Typography>
              <Typography>
                <Trans>
                  Our aim is to become the most comprehensive one-stop shop for
                  all our partners, to give our clients the most complete set of
                  functionalities, dealing services, technology solutions and
                  digital tools available all under a single ecosystem.
                </Trans>
              </Typography>
            </Stack>
          }
          reverse
        />
        <Stack mt={3} mb={10} spacing={2}>
          <Typography variant="h3">
            <Trans>Our Service Offering</Trans>
          </Typography>
          {contentOferring().map(({ title, description, image }) => (
            <BoxDetails title={title} description={description} image={image} />
          ))}
        </Stack>
        <Box mt={10}>
          <InterestedItems>
            <Grid container spacing={2}>
              {contentImages().map(({ image, title, description, path }) => (
                <Grid item xs={12} md={6} sx={{ minHeight: "400px" }}>
                  <ImageCard
                    image={image}
                    title={title}
                    description={description}
                    linkPath={path}
                  />
                </Grid>
              ))}
            </Grid>
          </InterestedItems>
        </Box>
      </Container>
      <Box sx={{ backgroundColor: (theme) => theme.palette.primary.main }}>
        <Container pt={8} pb={10}>
          {contentStructure().map(
            ({ title, description, country, code, color, content }, index) => (
              <StyledParent
                level={index}
                p={2}
                mb={showStructureContent === index ? -3 : 2}
                sx={{
                  ml: {
                    xs: 0,
                    sm: index * 6,
                  },
                  backgroundColor:
                    showStructureContent === index
                      ? "rgba(245, 245, 245, 0.5)"
                      : color,
                  borderRadius:
                    showStructureContent === index ? "5px 5px 0 0" : "5px",
                }}
              >
                <Box display="flex" justifyContent="space-between">
                  <StyledTypography parentColor={color} variant="h4">
                    {title}
                  </StyledTypography>
                  {content ? (
                    <StyledIcon
                      onClick={() =>
                        setShowStructureContent(
                          showStructureContent === index ? undefined : index,
                        )}
                      icon={
                        showStructureContent === index
                          ? faChevronUp
                          : faChevronDown
                      }
                    />
                  ) : (
                    renderCountryFlag(country, code, color)
                  )}
                </Box>
                <Typography color="white">{description}</Typography>
                {content && (
                  <Box mt={2}>{renderCountryFlag(country, code, color)}</Box>
                )}
              </StyledParent>
            ),
          )}
          {showStructureContent && (
            <Grid item xs={12} mt={3}>
              <Box
                sx={{
                  background: "rgba(245, 245, 245, 0.5)",
                  borderRadius: {
                    xs: "0px 0px 5px 5px",
                    sm: "5px 0px 5px 5px",
                  },
                }}
              >
                <Grid container padding={2} spacing={2}>
                  {contentStructure()[showStructureContent].content.map(
                    ({ title, items }) => (
                      <Grid item xs={12} sm={4}>
                        <Box textAlign="center">
                          <Typography variant="text" color="white">
                            {title}
                          </Typography>
                        </Box>
                        {renderChilds(items)}
                      </Grid>
                    ),
                  )}
                  <Grid item xs={12} textAlign="end">
                    <Typography color="white">
                      * <Trans>Company 65% owned by Allfunds Digital</Trans>
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          )}
        </Container>
      </Box>
    </NewLayout>
  );
}

const StyledParent = styled(Box)`
  position: relative;
  > div {
    ${({ level }) =>
      `${
        level !== 0 &&
        `
    &:before {
      content: "";
      height: ${`${level > 1 ? 60 : 70}%`};
      width: 10px;
      border-bottom: 3px solid white;
      border-left: 3px solid white;
      position: absolute;
      bottom: ${`${level > 1 ? 70 : 35}px`};
      left: -25px;
    }
  `
      }`};
  }
`;

const StyledSubMenu = styled(Box)`
  position: relative;
  padding: 0;
  margin-left: ${({ level, theme }) => `${theme.spacing(level * 3)}`};
  > div {
    position: relative;
    background: white;
    border-radius: 5px;
    ${({ level, isFirst, theme, size }) =>
      `${
        level !== 0 &&
        `
      &:before {
        content: "";
        height: ${`${isFirst ? 100 : size || 120}%`};
        width: 10px;
        border-bottom: ${`3px solid ${theme.palette.primary.main}`};
        border-left: ${`3px solid ${theme.palette.primary.main}`};
        position: absolute;
        bottom: 10px;
        left: -15px;
      }
    `
      }`};
  }
`;

const StyledTypography = styled(Typography)`
  color: ${({ parentColor }) => `${parentColor !== "white" && "white"}`};
`;

const StyledIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
  background: ${({ theme }) => `${theme.palette.primary.main}`};
  padding: 0.5rem;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  color: white;
`;

export default AboutPage;
